<template>
  <ion-refresher slot="fixed" @ionRefresh="getData($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <div class="my-4 section-title">ATTIVI</div>
  <!-- </ion-card> -->
  <div
    v-if="loading"
    style="height: 80px"
    class="flex flex-column justify-content-center align-items-center"
  >
    <span>Caricamento in corso...</span>
    <ion-skeleton-text :animated="true" style="width: 88%"></ion-skeleton-text>
  </div>
  <div v-else>
    <div v-if="items.length > 0" class="lista-contenitore">
      <transition-group name="block-slide-in" :style="{ '--i': items.length }">
        <template v-for="(item, i) in items">
          <div
            :key="i"
            :style="{ '--i': i }"
            v-if="showList1 && showList2"
            class="ion-no-padding ion-margin-top"
            style="overflow: visible"
          >
            <CardCollapsable class="ion-margin-bottom">
              <template #title>
                <span class="font-bold">{{ item.titolo }}</span>
              </template>
              <template #body>
                <div class="flex flex-column">
                  <!-- <span class="text-12 mb-4 text-color-light">{{
                    item.annotazioni
                  }}</span> -->
                  <span class="text-color-light"
                    ><span class="font-bold">Dossier: </span
                    >{{ item.Dossier }}</span
                  >
                  <span class="text-color-light"
                    ><span class="font-bold">Cliente: </span
                    >{{ item.Cliente }}</span
                  >
                </div>
              </template>
              <template #footer>
                <div class="flex justify-content-start w-full mt-4">
                  <button @click="goto(item)" class="text-button">ENTRA</button>
                </div>
              </template>
            </CardCollapsable>
          </div>
        </template>
      </transition-group>
    </div>
    <div v-else>
      <ion-row class="ion-justify-content-center ion-align-items-center">
        <ion-col size="auto">
          <span style="color: gray">Questa lista è vuota</span>
        </ion-col>
      </ion-row>
    </div>
  </div>
  <div class="my-4 section-title">CHIUSI</div>
  <div
    v-if="loading"
    style="height: 80px"
    class="flex flex-column justify-content-center align-items-center"
  >
    <span>Caricamento in corso...</span>
    <ion-skeleton-text :animated="true" style="width: 88%"></ion-skeleton-text>
  </div>
  <div v-else>
    <div v-if="pastItems.length > 0" class="lista-contenitore">
      <transition-group
        name="block-slide-in"
        :style="{ '--i': pastItems.length }"
      >
        <template v-for="(item, i) in pastItems">
          <div :key="i" :style="{ '--i': i }" v-if="showList1 && showList2">
            <CardCollapsable class="ion-margin-bottom" :not-collapsable="true">
              <template #title>
                <div class="flex justify-content-between align-items-center">
                  <span class="font-bold">{{ item.Dossier }}</span>
                  <span class="text-color-light ml-4">"{{ item.Cliente }}</span>
                </div>
              </template>

              <template #footer>
                <div class="flex justify-content-end w-full">
                  <button @click="goto(item)" class="text-button">ENTRA</button>
                </div>
              </template>
            </CardCollapsable>
          </div>
        </template>
      </transition-group>
    </div>
    <div v-else>
      <ion-row class="ion-justify-content-center ion-align-items-center">
        <ion-col size="auto">
          <span style="color: gray">Questa lista è vuota</span>
        </ion-col>
      </ion-row>
    </div>
  </div>
</template>

<script>
import {
  IonAvatar,
  IonLabel,
  IonSelectOption,
  IonRow,
  IonCol,
  IonCard,
  IonList,
  IonItem,
  IonInput,
  IonSelect,
  IonTextarea,
  IonIcon,
  IonButton,
  IonFab,
  IonFabButton,
  modalController,
  loadingController,
  IonLoading,
  IonRefresher,
  IonRefresherContent,
  IonSkeletonText,
} from "@ionic/vue";
import { pin } from "ionicons/icons";
import ApiService from "../../common/service.api";
import {
  warningOutline,
  add,
  chatboxOutline,
  chatbox,
  checkmarkCircleOutline,
  alertCircleOutline,
} from "ionicons/icons";
import AxertaChat from "../Modals/AxertaChat.vue";
import AxertaNewLog from "../Modals/AxertaNewLog.vue";
import MasterLayout from "../../components/MasterLayout";
import { mapGetters } from "vuex";
import CardCollapsable from "../../components/Nuovi/CardCollapsable.vue";

export default {
  name: "Lista Ordini",
  components: {
    IonAvatar,
    IonLabel,
    IonSelectOption,
    IonRow,
    IonCol,
    IonCard,
    IonList,
    IonItem,
    IonInput,
    IonSelect,
    IonTextarea,
    IonIcon,
    IonButton,
    IonFab,
    IonFabButton,
    MasterLayout,
    IonLoading,
    CardCollapsable,
    IonSkeletonText,
    IonRefresher,
    IonRefresherContent,
  },

  data() {
    return {
      showList1: false,
      showList2: false,
      loading: false,
      pin,
      warningOutline,
      add,
      chatboxOutline,
      chatbox,
      checkmarkCircleOutline,
      alertCircleOutline,
      categorySelected: this.$route.query.categoriaSelezionata,
      totaleGenerale: 0,
      items: [],
      pastItems: [],
      options: [
        {
          text: "Tutte le categorie",
          value: 0,
          margine: 0,
        },
      ],
    };
  },
  created() {
    this.showList1 = false;
    this.showList2 = false;
    this.getData();
    window.loadingCPresented = null;
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.showList2 = true;
      });
    }, 1);
  },

  methods: {
    getData(event = null) {
      this.loading = true;
      ApiService.get("DossierManager/GetOrdiniDiServizio/0")
        .then((res) => {
          this.items.splice(0);
          this.pastItems.splice(0);
          const today = new Date();
          res.data.forEach((ordine) => {
            ordine.collapsed = true;
            let dataOrdine = new Date(ordine.dataOra_inizio);
            dataOrdine.setDate(dataOrdine.getDate() + 1);

            if (new Date(dataOrdine) < new Date(today)) {
              this.pastItems.push(ordine);
            } else {
              this.items.push(ordine);
            }
          });

          this.showList1 = true;
        })
        .finally(() => {
          this.loading = false;
          if (event) {
            event.target.complete();
          }
        });
    },

    nameFromId(Id) {
      return this.options.filter((element) => Id == element.id).nome;
    },
    goto(item) {
      this.$router.push("/axerta/descrizione-ordine/" + item.id);
    },

    modalCloseHandler(item) {},
    async openModalChat(item) {
      const modal = await modalController.create({
        component: AxertaChat,
        cssClass: "common-modal",
        componentProps: {
          item: item,
        },
      });
      await modal.present();

      let modalResponse = await modal.onDidDismiss();
      console.log("MODAL RESPONSE", modalResponse);

      modalResponse && this.modalCloseHandler();
    },
    async openModalNewKpiChat() {
      let options = this.options;

      const modal = await modalController.create({
        component: AxertaNewLog,
        cssClass: "common-modal",
        componentProps: { options },
      });

      modal.onDidDismiss().then(async () => {
        console.log("modal closed ax");
      });
      return modal.present();
    },
  },
  computed: {
    ...mapGetters("loading", {
      isLoading: "isLoading",
    }),
    categorySelectedText() {
      let string = "";
      this.options.forEach((element) => {
        if (element.value == this.categorySelected) {
          string = element.text;
        }
      });
      return string;
    },

    totale() {
      let int = 0;
      this.computed_listItems.forEach((element) => {
        int += element.margineValue;
      });
      return int;
    },
    computed_debug() {
      if (this.$cookies.get("debug") == 1) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
